@import "~antd/dist/antd.less";
@import "./../../variables.less";

@inner-border-color: #d9d9d9;

.ctc-inner-border {
    border: 1px solid @inner-border-color;
}

.custom-transfer-container {
    .main-border;
    .padding-2px(5);
    .flex-row-between;
    background: #fcfcfc;

    .left-panel {
        width: 100%;
        padding-right: @px-10;

        .label-div {
            .padding-2px(5, 2.5);
        }

        .items-panel {
            .item {
                .ctc-inner-border;
                background: @bg-white;
                box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
                margin-bottom: @pxh-10;

                .item-title {
                    .font-size-2px-bold(8);
                }

                .item-children-panel {
                    .option-item {
                        .padding-2px(2.5, 5);
                        .margin-2px(2.5, 5);
                        margin-left: 0;
                        display: inline-block;
                        background: #ededed;
                        cursor: pointer;

                        &.active {
                            background: linear-gradient(180deg, rgba(0, 75, 87, 0.83) 0%, #004b57 100%);
                            color: @color-white;
                        }
                    }
                }
            }
        }
    }
    .right-panel {
        background: @bg-white;

        .sorting-panel {
            .ctc-inner-border;
            width: @px-100 * 2.2;

            .title {
                .padding-2px(4, 7.5);
                border-bottom: 1px solid @inner-border-color;
            }
            .content {
                min-height: 40vh;
                padding-top: @pxh-2;

                .sort-item {
                    .ctc-inner-border;
                    .padding-2px(2.5);
                    .margin-2px(1);
                    margin-top: 0;
                    cursor: pointer;

                    .anticon {
                        margin-right: @px-10;
                    }
                }
            }
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;