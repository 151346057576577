@import "~antd/dist/antd.less";
@import "./../../variables.less";

.date-range2 {
    display: flex;

    .date-range-div {
        .apply-date-range {
            margin-top: @pxh-10;
        }
    }

    .select-range-div {
        border-left: 1px solid #d9d9d9;

        .search-box {
            .margin-2px(0, 5);
            width: 90%;
        }

        .custom-select-list {
            overflow: auto;
            height: @pxh-100 * 2;
            margin-top: @pxh-10;

            .relative-date-range-list-item {
                border-bottom: none;
                .padding-2px(3, 11);

                &.selected {
                    background-color: #91a19d !important;
                }
            }

            .relative-date-range-list-item:hover {
                background-color: #f0f0f0;
                cursor: pointer;
            }
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;