@import "~antd/dist/antd.less";
@import "./../../variables.less";

.ant-collapse-header,
.ant-collapse-content {
    border-bottom: 1px solid @main-border-color;
}

.flt-f-c-btns {
    button {
        width: @px-100;
        margin-right: @px-10;
    }
}
.divider-nogap {
    .margin-2px-important(6, 0);
}

.disabled-filter-panel {
    .flex-row-between;
    .padding-2px(2, 8);
    //height: @pxh-10 * 4.3;
    background: #fafafa;
    align-items: center;
    border-bottom: 1px solid @main-border-color;

    .title {
        font-size: @font-size-16;
        font-weight: 500;
    }

    .extra {
        .flex-row;
        justify-content: center;
    }
}

.ant-form-vertical {
    .ant-form-item {
        margin-bottom: 0px;

        .ant-form-item-label {
            padding-bottom: @pxh-2;
        }
    }
}

.counter-panel {
    .flex-row;
    justify-content: flex-end;
    //min-width: @px-100 * 1.5;
    text-align: right;

    &.less {
        //min-width: @px-100 * 1.4;
    }
}

.flexi-filter {
    .ant-collapse-header {
        align-items: center !important;
        flex-wrap: wrap !important;
        font-size: @font-size-10;
        .ant-collapse-extra {
            .flex-row;
            justify-content: center;
        }

        .ant-collapse-expand-icon {
            .flex-row;
            align-items: center;

            .flexi-table-uploading-container {
                .flex-row;
                .padding-2px(1.5, 7.5);
                align-items: center;
                margin-left: @px-10;
                border: 1px solid #538d97;
                border-radius: 5px;
                background: #f9f9f9;

                & > span {
                    font-size: @font-size-14;
                    margin-left: @px-5 * 1.5;
                    color: #666666;
                }

                .anticon-loading {
                    font-size: @font-size-16;
                }
            }
        }
    }

    .ant-collapse-content-box {
        .padding-2px-important(5, 8);
        background-color: @bg-white;
    }

    .ant-divider-horizontal {
        margin-bottom: @pxh-10 !important;
        margin-top: 0 !important;
    }
}

.show-columns-panel {
    .ant-checkbox-group {
        .flex-column;

        .ant-checkbox-group-item {
            margin-bottom: @pxh-5;
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;