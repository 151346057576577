@import "~antd/dist/antd.less";
@import "./../../../variables.less";

.hub-switcher-container {
    min-height: 70vh;

    .list-panel {
        .flex-column;

        .tab-container {
            .ant-tabs-nav {
                margin-bottom: 0;

                .ant-tabs-tab {
                    border: 1px solid #e7e7e7 !important;
                    border-bottom: none !important;
                }
            }
        }
        .content {
            background: @bg-white;
            margin-bottom: @pxh-20;
        }

        .hub-switcher-content-navs {
            .padding-2px(5);
            background: @bg-white;
            display: flex;
            justify-content: space-between;
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;