@import "~antd/dist/antd.less";
@import "./../../variables.less";

.form-select {
    margin-bottom: @pxh-10 * 1.2;
}

.withResyncBtn {
    &.ant-form-item {
        .ant-form-item-control-input-content {
            display: flex;
            flex-direction: row;
        }
    }
}

.form-input-tree {
    .padding-2px(7.5, 5);
    border: 1px solid #d9d9d9;
}

.message-template-input {
    .title {
        .main-border-2;
        .padding-2px(0.5, 2.5);
        .font-size-2px-bold(7);
        border-bottom: 0;

        .anticon {
            margin-left: 5px;
            color: #ff7f00;
        }
    }

    .metric-container {
        .main-border-2;
        .padding-2px(2);
        box-shadow: inset 0px 0px 3px #cccccc;
        background: #fafafa;
        border-bottom: 0;
        padding-bottom: 0;

        & > .ant-typography {
            .padding-2px(0.5, 2);
            display: inline-block;
            border: 1px solid #bcbcbc;
            border-radius: 5px;
            margin-right: 3px;
            margin-bottom: 3px;
            cursor: pointer;

            & > strong {
                .flex-row;
                align-items: center;

                .anticon {
                    .font-size-2px(5.5);
                    margin-right: 3px;
                }
            }
        }
    }
}
// .message-template-input {
//     .form-input-outer-container {
//         .flex-row-between;

//         .metric-container {
//             .flex-column;
//             .main-border-2;
//             .padding-2px(1);
//             background: #fafafa;
//             border-right: 0;
//             padding-right: 0;

//             .title {
//                 border-bottom: 1px solid @border-color-2;
//                 margin-bottom: 5px;
//                 padding-left: 5px;

//                 .anticon {
//                     margin-left: 5px;
//                     color: #ff7f00;
//                 }
//             }

//             & > .ant-typography {
//                 .padding-2px(0.5, 2);
//                 display: inline-block;
//                 border: 1px solid #bcbcbc;
//                 border-radius: 3px;
//                 margin-right: 3px;
//                 margin-bottom: 3px;
//                 cursor: pointer;

//                 & strong {
//                     .flex-row-between;
//                 }
//             }
//         }

//         & > .ant-form-item {
//             flex: 1;
//             margin-bottom: 0;

//             textarea {
//                 box-shadow: inset 1px 1px 3px #d8d8d8;
//             }
//         }
//     }
// }

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;