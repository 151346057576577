@import "~antd/dist/antd.less";
@import "./../../variables.less";

.label-display-list {
    .label-display-list-row {
        .padding-2px(5, 0);
    }
}

.timer-button {
    .flex-row;
    justify-content: center;

    .spin-bg {
        display: inline-block;
        margin-right: @px-2 * 4;
        width: @px-20 * 1.2;
        height: @px-20 * 1.2;

        &:after {
            .margin-2px(1);
            content: " ";
            position: absolute;
            top: 0;
            left: calc(20%);
            width: @font-size-22;
            height: @font-size-22;
            border-radius: 50%;
            border: 1px solid #808080;
            border-color: #808080 transparent #808080 transparent;
            animation: spin-bg 1.2s linear infinite;
        }

        .ant-statistic-content {
            font-size: @font-size-14;

            span {
                .padding-2px(0, 2.5);
                font-size: @font-size-14;
                font-weight: 300;
                color: #808080;
                margin-right: @px-5;
                //border: 1px solid #808080;
                border-radius: 30px;
            }
        }

        &.hr {
            width: @px-10 * 5.8;

            &:after {
                left: @px-10 * 3.2;
            }
        }

        &.min {
            width: @px-10 * 4.2;

            &:after {
                left: @px-10 * 2.2;
            }
        }

        &.sc {
            width: @px-10 * 2.2;

            &:after {
                left: @px-10 * 1.3;
            }
        }
    }
}

@keyframes spin-bg {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;