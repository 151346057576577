.kbar-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 20vh;
    z-index: 1000;
}

.kbar-container {
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.kbar-search {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #eee;
    outline: none;
}

.kbar-results {
    max-height: 300px;
    overflow-y: auto;
}

.kbar-result-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.kbar-result-item-active {
    background-color: #f3f4f6;
}

.kbar-section-header {
    padding: 8px 16px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #888;
    background: rgba(0,0,0,0.05)
}