@import "~antd/dist/antd.less";
@import "./../../../variables.less";

.TemplateSetupContainer,
.ViewTemplateContainer {
    padding-top: @pxh-10;

    .divider-title {
        .font-size-2px(9);
        .padding-2px(1.5, 15);
        margin-left: @px-10 * -2.8;
        margin-top: @pxh-25;
        margin-bottom: @pxh-20;
        font-weight: 500;
        color: @color-white;
        background: @main-app-color;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
    }

    .step-div {
        .padding-2px(0, 5);
        margin-bottom: @pxh-50;
    }

    .center-container {
        display: flex;
        justify-content: center;
        align-items: center;

        &.success-message {
            padding-top: @pxh-30;
        }
    }

    .step-content {
        .SchedulerSetupContainer {
            .title {
                .font-bold;
            }

            .receipiant-div {
                .flex-row;

                .email-input {
                    .padding-2px(2.5);
                    .main-border-3;
                    margin-right: @px-20;

                    .email-list {
                        .flex-row;
                        flex-wrap: wrap;

                        .ant-tag {
                            .padding-2px(2.5);
                            margin-bottom: @pxh-5;
                        }
                    }

                    .form-text {
                        margin-bottom: 0 !important;
                    }

                    .email-input-element {
                        display: inline-block;
                        margin-bottom: 0;
                        width: @px-100 * 2.2;

                        input {
                            width: @px-100 * 2;
                            border-style: none;
                        }
                    }
                }

                .cb-email {
                    margin-bottom: 0 !important;
                    margin-top: @pxh-5;
                    width: @px-100 * 1.8;
                }
            }

            .tagging-div {
                .cb-tagging {
                    width: @px-100 * 1.8;
                }
            }

            .scheduled-list {
                .main-border-3;

                .scheduled-list-title {
                    .flex-row-between;
                    .padding-2px(5, 7.5);
                    align-items: center;

                    h1 {
                        margin-bottom: 0;
                    }
                }
                .list-item-div {
                    border-top: 1px solid @border-color-3;

                    .list-item-style {
                        .flex-row-between;
                        .padding-2px(5, 7.5);
                        align-content: center;
                        border-bottom: 1px solid @border-color-3;

                        &:last-child {
                            border-bottom: none;
                        }

                        span {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .empty-list-item {
                        .padding-2px(15);
                    }
                }
            }
        }
    }

    // NEW CREATE EDIT TEMPLATE UI -------------------- START
    .title {
        .font-bold;
    }

    .receipiant-div {
        .email-input {
            .main-border-3;
            .padding-2px(2.5);

            .email-list {
                .flex-row;
                flex-wrap: wrap;

                .ant-tag {
                    .padding-2px(2.5);
                    margin-bottom: @pxh-5;
                }
            }

            .form-text {
                margin-bottom: 0 !important;
            }

            .email-input-element {
                display: inline-block;
                margin-bottom: 0;
                width: @px-100 * 2.2;

                input {
                    width: @px-100 * 2;
                    border-style: none;
                }
            }
        }

        .cb-email {
            margin-bottom: 0 !important;
            margin-top: @pxh-5;
            width: @px-100 * 1.8;
        }
    }

    .tagging-div {
        .cb-tagging {
            width: @px-100 * 1.8;
            margin-top: @pxh-20;
            margin-bottom: 0 !important;
        }
    }

    .scheduled-list {
        .main-border-3;

        .scheduled-list-title {
            .flex-row-between;
            .padding-2px(5, 7.5);
            align-items: center;

            h1 {
                margin-bottom: 0;
            }
        }
        .list-item-div {
            border-top: 1px solid @border-color-3;

            .list-item-style {
                .flex-row-between;
                .padding-2px(5, 7.5);
                align-content: center;
                border-bottom: 1px solid @border-color-3;

                &:last-child {
                    border-bottom: none;
                }

                span {
                    display: flex;
                    align-items: center;
                }
            }

            .empty-list-item {
                .padding-2px(15);
            }
        }
    }
    // NEW CREATE EDIT TEMPLATE UI -------------------- END

    .divider-left {
        border-left: 2px solid @border-color-3;
    }

    .anchor-container {
        margin-right: @px-25;
        padding-right: @px-50 * 1.5;
        border-right: 1px solid #e5e3e3;
    }

    .error-container {
        .padding-2px(5);
        padding-bottom: @pxh-2 * 0.5;
        padding-left: @px-30;
        margin-left: @px-30;
        margin-bottom: @px-10;
        background-color: #f8dbdb;
        width: 75%;
        border-radius: 5px;
    }
}

.ViewTemplateContainer {
    .inline-card-style {
        .metric-content,
        .scheduler-content {
            .padding-2px(5, 7.5);
            padding-top: 0;
            background-color: #f7f7f7;
            border: 1px dashed #dbdbdb;
            border-radius: 8px;

            .custom-filter-content,
            .selected-metric-content,
            .schedular-list {
                margin-top: @pxh-20;
            }
            .custom-filter-content {
                padding-left: @px-20;
            }
        }
        .ant-card-head {
            padding: 0;

            .ant-card-head-title {
                padding-left: @px-20;
                padding-right: @px-20;
                background-color: #fafafa;
            }
        }
        .ant-card-body {
            .padding-2px(10);
        }

        .with-border {
            border-right: 1px dotted @border-color-3;
        }
    }
    .footer-button {
        padding-top: @pxh-20;
    }
}

.ViewTemplateContainerV2 {
    .ant-card-grid {
        .padding-2px(7.5);
    }

    & > div {
        margin-bottom: @pxh-15 !important;

        &.ant-card-grid {
            padding: 0 !important;
        }

        & > .ant-card-grid {
            padding: 0 !important;
            margin-right: @px-10 !important;

            &:last-child {
                margin-left: @px-10 !important;
                margin-right: 0 !important;
            }
        }
    }

    .inline-card-style {
        margin-bottom: @pxh-20;
        .metric-content,
        .scheduler-content,
        .filter-sorter-content {
            .padding-2px(5, 7.5);
            padding-top: 0;
            background-color: #f7f7f7;
            border: 1px dashed #dbdbdb;
            border-radius: 8px;

            .custom-filter-content,
            .selected-metric-content,
            .schedular-list {
                margin-top: @pxh-20;
            }
            .custom-filter-content {
                padding-left: @px-20;
            }
        }
        .ant-card-head {
            padding: 0;

            .ant-card-head-title {
                padding-left: @px-20;
                padding-right: @px-20;
                background-color: #fafafa;
            }
        }
        .ant-card-body {
            .padding-2px(10);
        }

        .with-border {
            border-right: 1px dotted @border-color-3;
        }
    }
    .footer-button {
        padding-top: @pxh-20;
    }
}

.scheduled-div {
    .tab-content-cls {
        .padding-2px(10, 0);

        .check-box-style {
            display: flex;
            justify-content: left;
            padding-left: @px-10;
        }
    }
}

.error {
    color: @color-error;
}
.error-border {
    border-color: @color-error;
}
.hidden-wrapper-form-item.ant-form-item-has-error {
    .ant-form-item-control-input {
        border: 1px solid @color-error;
    }
}
.mb-1 {
    margin-bottom: @pxh-10;
}
.mb-2 {
    margin-bottom: @pxh-20;
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;