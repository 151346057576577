@import "~antd/dist/antd.less";
@import "./../../../variables.less";

.card-box-container {
    .padding-2px(12, 0);

    .inner-container {
        .flex-column;
        background-color: @bg-white;
        position: relative;
        border-radius: 0.5rem;

        .card-box-header {
            .padding-2px(6, 8);
            .margin-2px(-12, 5);
            .font-size-2px-bold(9);
            .flex-row-between;
            margin-bottom: 0;
            background: linear-gradient(195deg, #035462, #034854);
            color: @color-white;
            border-radius: 0.5rem;
            box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);
        }

        .card-box-content {
            padding-top: @pxh-10;
        }
    }

    &.small {
        .card-box-header {
            margin-top: @px-10 * -0.8;
            background: @main-app-color;
            box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
            border-radius: 8px;
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;